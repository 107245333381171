import machineInfo from '../documents/machines' // <---- le fichier json

export default class MachineData {
    static get machineInfo(){
        return machineInfo
    }
    static machineType = () => {
        let machine = this.machineInfo;
        return Object.keys(machine).map((e) => {
            return e;
        })
    }
}