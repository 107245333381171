import React, { Component } from 'react';
import RangeInput from './components/rangeInput';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/noremat_0.png'
import Chart from 'react-google-charts';
import './scss/custom.scss'
import './app.css'

class App extends Component {

	constructor(props) {
		super(props)
		window.jQuery = window.$ = require('jquery/dist/jquery.min');
		require('bootstrap/dist/js/bootstrap.min.js');

		this.state = {
			selectedType : 'porteur',
			machine: JSON.parse(JSON.stringify(this.props.MachineData.machineInfo.porteur.vsv)),
			history: [],
			historyJSX: []
		};
		this.recoverHistory = this.recoverHistory.bind(this);
		this.setHistory = this.setHistory.bind(this);
		this.getRangeInputCollection = this.getRangeInputCollection.bind(this);
		this.changeMachine = this.changeMachine.bind(this);
		this.selectedType = this.selectedType.bind(this);
		this.handleRangeInput = this.handleRangeInput.bind(this);
	}


	handleRangeInput(data) {
		let machineUpdated = this.state.machine.map((inputProps) => {
			if(data.as === "Prix d'acquisition") {
				if(inputProps.as === "Valeur à la revente") {
					// console.log(data.max, 'ici')
					inputProps.max = parseInt(data.value);
				}
			}
			if(data.as === inputProps.as) {
				inputProps.value = parseInt(data.value);
			} 
			return inputProps;
		});
		this.setState({
			machine: machineUpdated
		});
	}

	calcul() {

		let data = {};
		this.state.machine.forEach((element) => {
			data[element.name] = element; 
		})
		let depreciation = (data.acquisition_price.value - data.value_at_resale.value);
		let insurance = (data.annual_insurance.value * data.duration_of_use.value)
		let borrowingCost = data.acquisition_price.value * (data.borrowing_rate.value / 100);
		let fixPrice =  depreciation +  borrowingCost + insurance;
		let energieCost = data.cost_of_diesel_per_liter.value * data.average_consumption.value * (data.number_of_hours_per_year.value * data.duration_of_use.value);
		let tiresCost = data.cost_of_4_tires.value * (data.tires_lifespan.value / (data.number_of_hours_per_year.value * data.duration_of_use.value));
		let variablePrice = energieCost + tiresCost + data.maintenance.value;
		let operatorSalary = data.operator_annual_cost.value * data.duration_of_use.value;
		let operatorFormation = data.annual_training_cost.value * data.duration_of_use.value;
		let operatorPrice = operatorSalary + operatorFormation;
		let totalHours = (data.number_of_hours_per_year.value * data.duration_of_use.value);
		let total = fixPrice + variablePrice + operatorPrice;
		let totalCostPerYears = total /  data.duration_of_use.value;
		let totalCostPerhours = total /  totalHours;
		let variablePricePerHours = variablePrice / totalHours;
		let energieCostPerHours = energieCost / totalHours;
		let tiresCostPerHours = tiresCost / totalHours;
		let maintenancePerHours = data.maintenance.value / totalHours;
		let fixPricePerHours = fixPrice / totalHours;
		let depreciationPerHours = depreciation / totalHours;
		let insurancePerHours = insurance / totalHours;
		let borrowingCostPerHours = borrowingCost / totalHours;
		let operatorPricePerHours = operatorPrice / totalHours;
		let operatorFormationPerHours = operatorFormation / totalHours;
		let operatorSalaryPerHours = operatorSalary / totalHours;

		return {
			totalCostPerYears: this.round(totalCostPerYears),
			totalCostPerhours: this.round(totalCostPerhours),
			totalFixCost:{
				main: this.round(fixPricePerHours),
				depreciation : this.round(depreciationPerHours),
				insurance : this.round(insurancePerHours),
				borrowingRate : this.round(borrowingCostPerHours)
			},
			totalVariableCost: {
				main: this.round(variablePricePerHours),
				energie: this.round(energieCostPerHours),
				tires: this.round(tiresCostPerHours),
				maintenance: this.round(maintenancePerHours),
			},
			totalCostOperator: {
				main: this.round(operatorPricePerHours),
				formation: this.round(operatorFormationPerHours),
				salary: this.round(operatorSalaryPerHours)
			}
		}
	}

	round(n) {
		return Math.round(n * 100) / 100;
	}

	changeMachine(e) {
		this.setState({
			machine : JSON.parse(JSON.stringify(this.props.MachineData.machineInfo[this.state.selectedType][e.target.value]))
		})
	}

	displayMachine(e){
		let machines = Object.keys(this.props.MachineData.machineInfo[this.state.selectedType]).map((e) => {
			return e;
		})
		return machines.map((e, index) => {
			return <option value={e} key={index}>{e}</option>
		})
	}

	selectedType(e) {
		let obj = this.props.MachineData.machineInfo[e.target.value];
		this.setState({
			machine: obj[Object.keys(obj)[0]],
			selectedType: e.target.value
		});
	}

	displayType() {
		let typesOfMachine = this.props.MachineData.machineType();
		return typesOfMachine.map((name, index) => {
			return <option value={name} key={index}>{name}</option>
		});
	}

	recoverHistory(e) {
		let recover = this.state.history[e.target.value].history;
		this.setState({
			machine: recover
		})
	}

	setHistory() {
		let historyJSX = this.state.historyJSX;
		let history = this.state.history; 
		let historyJSXlength = historyJSX.length;
		history.push(JSON.parse(JSON.stringify({history : this.state.machine})));
		historyJSX.push(<button key={historyJSXlength} value={historyJSXlength} type="button" className="btn btn-secondary btn-sm btn-block" onClick={this.recoverHistory}>historique #{historyJSXlength + 1}</button>)
		this.setState({
			history: history,
			machine: JSON.parse(JSON.stringify(this.props.MachineData.machineInfo.porteur.vsv)),
			historyJSX: historyJSX
		});
	}
	getRangeInputCollection() {
		return this.state.machine.map((input, index) => {
			return 	<RangeInput 
						value={input.value} 
						unit={input.unit} 
						min={input.min} 
						max={input.max} 
						step={input.step} 
						as={input.as} 
						handleInput={this.handleRangeInput} 
						key={index}
					/>
		})
	}

	render() {
		// console.log(this.machineDefault);
		let results = this.calcul();
		return (
			<div className="container">
				<div className="row justify-content-md-center">
					<div className="col-md-auto">
						<img src={logo} alt="Logo acoopilot" className="img-fluid"/>
					</div>
					<div className="col-md-auto">
						<p>Noremat sait combien il est important pour les utilisateurs de matériel de connaitre leurs coûts afin de pouvoir les maîtriser. A cet effet, Noremat vous propose ce calculateur de TCO (Total Cost of Ownership / Coût total de possession). Pour calculer le TCO de votre matériel, veuillez choisir votre configuration (type de machine) et ajuster les valeurs des différents paramètres. Le résultat vous est donné automatiquement sous forme de couts horaires et un graphique vous permet de visualiser l’importance des différents paramètres dans le TCO Global.</p>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="card">
							<div className="card-header">
								Configuration
							</div>
							<div className="card-body">
								<form className="needs-validation">
									<div className="form-group">
										<h5>Type de produit</h5>
										<select className="custom-select" onChange={this.selectedType}>
											{this.displayType()}
										</select>
									</div>
									<div className="form-group">
										<h5>Modèle</h5>
										<select className="custom-select" onChange={this.changeMachine}>
											{this.displayMachine()}
										</select>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col my-5">
						<h3>Décrivez l'utilisation de votre machine.</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-md-7 range-class">
						{this.getRangeInputCollection()}
					</div>
					<div className="col-md-5">
							
						<div className="accordion" id="accordionExample">
							<div className="card">
								<div className="card-header">
									<button className="btn btn-link noremat" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										TOTAL DES COÛTS FIXES : {results.totalFixCost.main} € par heure
									</button>
								</div>
								<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
									<div className="card-body">
										<table className="table">
											<tbody>
												<tr>
													<th scope="row" className='sepreciation'>depreciation</th>
													<td>{results.totalFixCost.depreciation} €</td>
													<td>par heure</td>
												</tr>
												<tr>
													<th scope="row" className='assurance'>assurance</th>
													<td>{results.totalFixCost.insurance} €</td>
													<td>par heure</td>
												</tr>
												<tr>
													<th scope="row"  className='financement'>Taux d'emprunt</th>
													<td>{results.totalFixCost.borrowingRate} €</td>
													<td>par heure</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-header" id="headingTwo">
									<h2 className="mb-0">
										<button className="btn btn-link collapsed noremat" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
											TOTAL COÛTS VARIABLES : {results.totalVariableCost.main}€ par heure
										</button>
									</h2>
								</div>
								<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
									<div className="card-body">
										<table className="table">
											<tbody>
												<tr>
													<th scope="row" className="energie">energie</th>
													<td>{results.totalVariableCost.energie} €</td>
													<td>par heure</td>
												</tr>
												<tr>
													<th scope="row" className="maintenance">Maintenance</th>
													<td>{results.totalVariableCost.maintenance} €</td>
													<td>par heure</td>
												</tr>
												<tr>
													<th scope="row" className="pneumatique">Pneumatiques</th>
													<td>{results.totalVariableCost.tires} €</td>
													<td>par heure</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="card">
								<div className="card-header" id="headingThree">
									<h2 className="mb-0">
										<button className="btn btn-link collapsed noremat" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
											TOTAL COÛT OPÉRATEUR : {results.totalCostOperator.main}€ par heure
										</button>
									</h2>
								</div>
								<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
									<div className="card-body">
										<table className="table">
											<tbody>
												<tr>
													<th scope="row" className="salaire">salaire</th>
													<td>{results.totalCostOperator.salary} €</td>
													<td>par heure</td>
												</tr>
												<tr>
													<th scope="row" className="formation">formation</th>
													<td>{results.totalCostOperator.formation} €</td>
													<td>par heure</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col my-2">
								<div className="card">
									<div className="card-body">
										<h5 className="card-title">UN COÛT TOTAL DE : {results.totalCostPerhours}€ PAR HEURE</h5>
										<p className="card-text">UN COÛT ANNUEL DE : {results.totalCostPerYears}€</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<label htmlFor="clientContact" className="h6">Contact du client</label>
									<input type="email" className="form-control" id="clientContact"/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 my-2">
								<button type="button" className="btn btn-secondary btn-lg" onClick={this.setHistory}> remise à zero</button>
							</div>
							<div className="col-md-6 my-2">
								<button type="button" className="btn btn-success btn-lg" disabled data-toggle="tooltip" data-placement="bottom" title="Comming soon">Imprimer le résultat</button>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								{this.state.historyJSX}
							</div>
						</div>
					</div>
				</div>
				<div className="row">
				<Chart
								width={'100%'}
								height={'550px'}
								chartType="PieChart"
								data={[
									['Couts', 'Euro par Heures'],
									['Depreciation', results.totalFixCost.depreciation],
									['Assurance', results.totalFixCost.insurance],
									['Financement', results.totalFixCost.borrowingRate],
									['Energie', results.totalVariableCost.energie],
									['Maintenance', results.totalVariableCost.maintenance],
									['Pneumatique', results.totalVariableCost.tires],
									['salaire', results.totalCostOperator.salary],
									['formation', results.totalCostOperator.formation],
								]}
								options={{
									slices: {
										0:{color: "#FF4136"},
										1:{color: "#FFDC00"},
										2:{color: "#2ECC40"},
										3:{color: "#FF851B"},
										4:{color: "#AAAAAA"},
										5:{color: "#111111"},
										6:{color: "#B10DC9"},
										7:{color: "#39CCCC"},
									},
									title: 'Coûts totaux par heures',
									legend: {maxLines:6, alignment: "center"}
								}}
							/></div>
				<hr></hr>
				<div className="row">
					<div className="col">
						<h3>
							Veuillez noter
						</h3>
						<p>
							Les résultats obtenus à partir de l'outil sont non contractuels, ils sont fournis à titre indicatif et représentent une estimation globale. Noremat décline toute responsabilité en cas de divergence entre l'estimation fournie et une différence constatée à l'usage, les paramètres remplis par le client étant notamment susceptibles de ne pas correspondre à la réalité de l'usage qui est fait de la machine.
						</p>
					</div>
				</div>
				
			</div>
			);
		}
	}
export default App;