import React, { Component } from 'react';


class RangeInput extends Component{
    constructor(props){
        super(props);
        this.state = {
            value : parseFloat(this.props.value),
            error : false,
            max : parseFloat(this.props.max),
            min : parseFloat(this.props.min),
            step : parseFloat(this.props.step),
        }
        this.handleInput = this.handleInput.bind(this);
    }
    handleInput(e) {
        let valueForm = e.target.value;
        let error = false;
        if (valueForm > parseFloat(this.props.max)) {
            error = "La valeur est trop haute";

        } else if (valueForm < parseFloat(this.props.min)){
            error = "La valeur est trop faible";

        } else if (valueForm % 1 !== 0 && e.target.step % 1 === 0) {
            error = "Les chiffres à virgules ne sont pas acceptés.";

        } else if (typeof parseFloat(valueForm) !== 'number') {
            error = "Seuls les chiffres sont acceptés";
        } 
        this.setState({
            value: valueForm,
            error: error
        })
        this.props.handleInput({as: this.props.as, max: this.props.max, min: this.props.min, value: valueForm, unit: this.props.unit, step: this.props.step });
    }
    propsBuilder(value) {
        return {
            max: parseFloat(value.max),
            min: parseFloat(value.min),
            step: parseFloat(value.step),
            value: parseFloat(value.value)
        }
    }
    componentDidUpdate(prevProps, prevState){
        // console.log(this.propsBuilder(this.props), this.propsBuilder(this.state));
        if (JSON.stringify(this.propsBuilder(this.props)) !== JSON.stringify(this.propsBuilder(this.state))) {
            this.setState({
                value: this.props.value,
                min: this.props.min,
                max: this.props.max
            })
        }
    }
    render() {
        if (this.state.min !== this.props.min) {
            this.setState({
                min: this.props.min
            });
        }
        return (
            <div className="row">
                <div className="col-md-4 mt-1">
                    {this.props.as}
                </div>
                <div className="col-md-4 mt-2">
                    <div className="form-group">
                        <input type="range" min={this.props.min} max={this.props.max} step={this.props.step} value={this.props.value} id="range1" onChange={(e) => this.handleInput(e)}>
                        </input>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div className="input-group">
                            <input type="text" className="form-control text-right" placeholder={this.state.value} value={this.state.value} onChange={(e) => this.handleInput(e)}>
                            </input>
                            <div className="input-group-append" >
                                <span className="input-group-text text-center" style={{minWidth: "75px", display: "inline"}}>
                                        {this.props.unit}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p>{this.state.error}</p>
                </div>
            </div>
        );
    }
}
export default RangeInput;